
import EventBus from '@/shared/services/event_bus'
import Flash   from '@/shared/services/flash'
import Records   from '@/shared/services/records'
import { compact, sortBy, without, isEqual, map } from 'lodash'

export default
  props:
    stance: Object

  data: ->
    selectedOptionIds: compact((@stance.pollOptionIds().length && @stance.pollOptionIds()) || [parseInt(@$route.query.poll_option_id)])
    selectedOptionId: @stance.pollOptionIds()[0] || parseInt(@$route.query.poll_option_id)
    options: []

  created: ->
    @watchRecords
      collections: ['pollOptions']
      query: (records) =>
        if @poll.optionsDiffer(@options)
          @options = @poll.pollOptionsForVoting() if @poll

  computed:
    singleChoice: -> @poll.singleChoice()
    hasOptionIcon: -> @poll.config().has_option_icon
    poll: -> @stance.poll()
    optionSelected: -> @selectedOptionIds.length or @selectedOptionId
    optionPrompt: -> (@selectedOptionId && Records.pollOptions.find(@selectedOptionId).prompt) || ''
    submitText: ->
      if @stance.castAt
        'poll_common.update_vote'
      else
        'poll_common.submit_vote'
    optionCountAlertColor: ->
      return 'warning' if !@singleChoice && @selectedOptionIds.length && (@selectedOptionIds.length < @poll.minimumStanceChoices || @selectedOptionIds.length > @poll.maximumStanceChoices)
    optionCountValid: ->
      (@singleChoice && @selectedOptionId) || (@selectedOptionIds.length >= @poll.minimumStanceChoices && @selectedOptionIds.length <= @poll.maximumStanceChoices)

  watch:
    selectedOptionId: -> 
      # if reason is not disabled, focus on the reson for this poll
      EventBus.$emit('focusEditor', 'poll-'+@poll.id)

  methods:
    submit: ->
      if @singleChoice
        @stance.stanceChoicesAttributes = [{poll_option_id: @selectedOptionId}]
      else
        @stance.stanceChoicesAttributes = @selectedOptionIds.map (id) =>
          poll_option_id: id
      actionName = if !@stance.castAt then 'created' else 'updated'
      @stance.save()
      .then =>
        Flash.success "poll_#{@stance.poll().pollType}_vote_form.stance_#{actionName}"
        EventBus.$emit('closeModal')
      .catch => true

    isSelected: (option) ->
      if @singleChoice 
        @selectedOptionId == option.id
      else
        @selectedOptionIds.includes(option.id)

    classes: (option) ->
      if @poll.isVotable()
        votingStatus = 'voting-enabled'
      else
        votingStatus = 'voting-disabled'

      if @optionSelected
        if @isSelected(option)
          ['elevation-2', votingStatus]
        else
          ['poll-common-vote-form__button--not-selected', votingStatus]
      else
        [votingStatus]


