
import Records from '@/shared/services/records'
import Flash   from '@/shared/services/flash'
import { addDays } from 'date-fns'

export default
  props:
    poll: Object
    close: Function

  created: ->
    @poll.closingAt = addDays(new Date, 7)

  methods:
    submit: ->
      @poll.reopen()
      .then =>
        @poll.processing = false
        Flash.success("poll_common_reopen_form.success", {poll_type: @poll.translatedPollType()})
        @close()
  data: ->
    isDisabled: false
