
import Records     from '@/shared/services/records'
import { times } from 'lodash'
import { hoursOfDay, timeFormat } from '@/shared/helpers/format_time'
import { format, parse, isValid } from 'date-fns'

export default
  props:
    value: Date
    min: Date
    dateLabel: Object
    timeLabel: Object

  created: ->
    @newValue = @value

  data: ->
    dateStr: @value && format(@value, 'yyyy-MM-dd') || ''
    timeStr: @value && format(@value, timeFormat()) || ''
    minStr:  @value && format(@min, 'yyyy-MM-dd') || ''
    dateMenu: false
    times: hoursOfDay()
    placeholder: format(new Date(), 'yyyy-MM-dd')
    validDate: (val) =>
      isValid(parse(val, "yyyy-MM-dd", new Date()))

  methods:
    updateNewValue: ->
      val = parse("#{@dateStr} #{@timeStr}", "yyyy-MM-dd #{timeFormat()}", new Date)
      return unless isValid(val)
      @newValue = val
      @$emit('input', @newValue)

  watch:
    dateStr: -> @updateNewValue()
    timeStr: -> @updateNewValue()

