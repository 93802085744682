
import Session from '@/shared/services/session'
import EventBus from '@/shared/services/event_bus'

export default
  props:
    model: Object

  methods:
    remove: (id) ->
      @model.tagIds.splice(@model.tagIds.indexOf(id), 1)
    openTagsSelectModal: ->
      EventBus.$emit 'openModal',
        component: 'TagsSelect',
        props:
          model: @model.clone()

  computed:
    items: ->
      @model.group().parentOrSelf().tags()

    actor: ->
      Session.user()

