
import Records      from '@/shared/services/records.coffee'
import openModal      from '@/shared/helpers/open_modal'
export default
  props:
    discussion: Object
  methods:
    openMoveCommentsModal: ->
      openModal
        component: 'MoveCommentsModal'
        props:
          discussion: @discussion
  computed:
    styles: ->
      { bar, top } = @$vuetify.application
      return
        display: 'flex'
        position: 'sticky'
        top: "#{bar + top}px"
        zIndex: 1
