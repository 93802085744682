
import Session  from '@/shared/services/session'
import Records  from '@/shared/services/records'
import EventBus from '@/shared/services/event_bus'
import Gravatar from 'vue-gravatar';
import { capitalize } from 'lodash'
import AppConfig from '@/shared/services/app_config'
import Flash   from '@/shared/services/flash'

export default
  components:
    'v-gravatar': Gravatar
  data: ->
    user: Session.user().clone()
    providers: AppConfig.identityProviders
    uploading: false
    progress: 0
    previous_uploaded_avatar: undefined
  methods:
    capitalize: capitalize
    iconClass: (provider) ->
      "mdi-" + if (provider == 'saml') then 'key-variant' else provider

    providerColor: (provider) ->
      switch provider
        when 'facebook' then '#3b5998'
        when 'google' then '#dd4b39'
        when 'slack' then '#e9a820'
        else
          @$vuetify.theme.themes.light.primary

    selectProvider: (provider) ->
      window.location = "#{provider.href}?back_to=#{window.location.href}"

    selectFile: ->
      @$refs.fileInput.click()

    uploadFile: ->
      @uploading = true
      Records.users.remote.onUploadSuccess = (response) =>
        Records.importJSON response
        EventBus.$emit 'updateProfile'
        EventBus.$emit 'closeModal'
        @uploading = false
      Records.users.remote.upload('upload_avatar', @$refs.fileInput.files[0], {}, (args) => @progress = args.loaded / args.total * 100)

    submit: (kind) ->
      @user.avatarKind = kind
      Records.users.updateProfile(@user)
      .then =>
        Flash.success 'profile_page.messages.picture_changed'
        EventBus.$emit 'updateProfile'
        EventBus.$emit 'closeModal'
      .catch => true

  created: ->
    Records.users.saveExperience("changePicture")
    Records.users.fetch
      path: 'avatar_uploaded'
    .then (res) => @previous_uploaded_avatar = res.avatar_uploaded

