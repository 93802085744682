import { render, staticRenderFns } from "./preview_collection.vue?vue&type=template&id=fe06475a&lang=pug&"
import script from "./preview_collection.vue?vue&type=script&lang=coffee&"
export * from "./preview_collection.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import ThreadPreview from '@/components/thread/preview.vue'
import { VList } from 'vuetify/lib/components/VList';
installComponents(component, {ThreadPreview,VList})
