
import PageLoader         from '@/shared/services/page_loader'
import Records from '@/shared/services/records'
import EventBus     from '@/shared/services/event_bus'
import { parseISO } from 'date-fns'

export default
  props:
    poll: Object

  data: ->
    stances: []
    per: 25
    loader: null

  created: ->
    @loader = new PageLoader
      path: 'stances'
      order: 'orderAt'
      params:
        per: @per
        poll_id: @poll.id

    @loader.fetch(@page).then => @findRecords()

    @watchRecords
      collections: ['stances', 'polls']
      query: => @findRecords()

  computed:
    page:
      get: -> parseInt(@$route.query.page) || 1
      set: (val) ->
        @$router.replace({query: Object.assign({}, @$route.query, {page: val})}) 

    totalPages: ->
      Math.ceil(parseFloat(@loader.total) / parseFloat(@per))

  watch:
    page: ->
      @loader.fetch(@page).then(=> @findRecords()).then => @scrollTo('#votes')
      @findRecords()

  methods:
    findRecords: ->
      if @loader.pageWindow[@page]
        chain = Records.stances.collection.chain().find(id: {$in: @loader.pageIds[@page]})
        chain = chain.simplesort('orderAt', true)
        @stances = chain.data()
      else
        @stances = []

