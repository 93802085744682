
import Records        from '@/shared/services/records'
import AbilityService from '@/shared/services/ability_service'
import RecordLoader   from '@/shared/services/record_loader'
import Session        from '@/shared/services/session'
import EventBus       from '@/shared/services/event_bus'
import {includes, some, compact, intersection, orderBy, slice, debounce, min, escapeRegExp, map} from 'lodash'
import LmoUrlService from '@/shared/services/lmo_url_service'
import { exact, approximate } from '@/shared/helpers/format_time'

export default
  data: ->
    loader: null
    group: null
    per: 25
    order: 'created_at desc'
    orders: [
      {text: @$t('members_panel.order_by_name'),  value:'users.name' }
      {text: @$t('members_panel.order_by_created'), value:'memberships.created_at' }
      {text: @$t('members_panel.order_by_created_desc'), value:'memberships.created_at desc' }
      {text: @$t('members_panel.order_by_admin_desc'), value:'admin desc' }
    ]
    memberships: []

  created: ->
    @onQueryInput = debounce (val) =>
      @$router.replace(@mergeQuery(q: val))
    , 500

    Records.groups.findOrFetch(@$route.params.key).then (group) =>
      @group = group

      EventBus.$emit 'currentComponent',
        page: 'groupPage'
        title: @group.name
        group: @group
        search:
          placeholder: @$t('navbar.search_members', name: @group.parentOrSelf().name)

      @loader = new RecordLoader
        collection: 'memberships'
        params:
          exclude_types: 'group'
          group_id: @group.id
          per: @per
          order: @order
          subgroups: @$route.query.subgroups

      @watchRecords
        collections: ['memberships', 'groups']
        query: @query

      @refresh()

  methods:
    exact: exact
    approximate: approximate

    query: ->
      chain = Records.memberships.collection.chain()
      switch @$route.query.subgroups
        when 'mine'
          chain = chain.find(groupId: {$in: intersection(@group.organisationIds(), Session.user().groupIds())})
        when 'all'
          chain = chain.find(groupId: {$in: @group.organisationIds()})
        else
          chain = chain.find(groupId: @group.id)

      chain = chain.sort (a, b) =>
        return -1 if (a.groupId == @group.id)
        return 1 if (b.groupId == @group.id)
        return 0

      userIds = []
      membershipIds = []
      chain.data().forEach (m) ->
        if !userIds.includes(m.userId)
          userIds.push(m.userId)
          membershipIds.push(m.id)

      # @memberships = Records.memberships.collection.find(id: {$in: membershipIds})

      # drop the chain, get a new one

      chain = Records.memberships.collection.chain().find(id: {$in: membershipIds})

      if @$route.query.q
        users = Records.users.collection.find
          $or: [
            {name: {'$regex': ["^#{@$route.query.q}", "i"]}},
            {email: {'$regex': ["#{@$route.query.q}", "i"]}},
            {username: {'$regex': ["^#{@$route.query.q}", "i"]}},
            {name: {'$regex': [" #{@$route.query.q}", "i"]}}
          ]
        chain = chain.find(userId: {$in: map(users, 'id')})

      switch @$route.query.filter
        when 'admin'
          chain = chain.find(admin: true)
        when 'accepted'
          chain = chain.find(acceptedAt: { $ne: null })
        when 'pending'
          chain = chain.find(acceptedAt: null)

      chain = chain.simplesort('id', true)

      @memberships = chain.data()

    refresh: ->
      @loader.fetchRecords
        from: 0
        q: @$route.query.q
        order: @order
        filter: @$route.query.filter
        subgroups: @$route.query.subgroups
      @query()

    invite: ->
      EventBus.$emit('openModal',
                      component: 'GroupInvitationForm',
                      props:
                        group: @group)

  computed:
    membershipRequestsPath: -> LmoUrlService.membershipRequest(@group)
    showLoadMore: -> !@loader.exhausted
    totalRecords: ->
      if @pending
        @group.pendingMembershipsCount
      else
        @group.membershipsCount - @group.pendingMembershipsCount

    canAddMembers: ->
      AbilityService.canAddMembersToGroup(@group) && !@pending

    showAdminWarning: ->
      @group.adminsInclude(Session.user()) &&
      @group.adminMembershipsCount < 2 &&
      (@group.membershipsCount - @group.adminMembershipsCount) > 0

  watch:
    '$route.query': 'refresh'


