
import AppConfig      from '@/shared/services/app_config'
import AbilityService from '@/shared/services/ability_service'
import Records  from '@/shared/services/records'
import EventBus  from '@/shared/services/event_bus'
import Session  from '@/shared/services/session'
import { groupPrivacy, groupPrivacyStatement } from '@/shared/helpers/helptext'
import { groupPrivacyConfirm } from '@/shared/helpers/helptext'
import Flash   from '@/shared/services/flash'
import { isEmpty, compact, debounce } from 'lodash'
import openModal from '@/shared/helpers/open_modal'

export default
  props:
    group: Object

  data: ->
    rules: {
      required: (value) -> !!value || 'Required.'
    }
    uploading: false
    progress: 0
    categories: [ 'board', 'party', 'coop', 'union', 'nonprofit', 'professional', 'government', 'community', 'other' ]
    parentGroups: []
    loadingHandle: false

  created: ->
    @watchRecords
      collections: ['groups', 'memberships']
      query: (records) =>
        @parentGroups = [{value: null, text: @$t('common.none')}]
        @parentGroups = @parentGroups.concat Session.user().parentGroups().
          filter((g) -> AbilityService.canCreateSubgroups(g)).
          map((g) -> {value: g.id, text: g.name})

    @suggestHandle = debounce ->
      # if group is new, suggest handle whenever name changes
      # if group is old, suggest handle only if handle is empty
      if @group.isNew() or isEmpty(@group.handle)
        @loadingHandle = true
        parentHandle = if @group.parentId
          @group.parent().handle
        else
          null
        Records.groups.getHandle(name: @group.name, parentHandle: parentHandle).then (data) =>
          @group.handle = data.handle
          @loadingHandle = false
    , 250

  mounted: ->
    @suggestHandle()

  watch:
    'group.parentId': ->
      @group.handle = ''
      @group.name = ''

  methods:
    submit: ->
      allowPublic = @group.allowPublicThreads
      @group.discussionPrivacyOptions = switch @group.groupPrivacy
        when 'open'   then 'public_only'
        when 'closed' then (if allowPublic then 'public_or_private' else 'private_only')
        when 'secret' then 'private_only'

      @group.parentMembersCanSeeDiscussions = switch @group.groupPrivacy
        when 'open'   then true
        when 'closed' then @group.parentMembersCanSeeDiscussions
        when 'secret' then false

      @group.save()
      .then (data) =>
        groupKey = data.groups[0].key
        Flash.success "group_form.messages.group_#{@actionName}"
        Records.groups.findOrFetchById(groupKey, {}, true).then (group) =>
          EventBus.$emit 'closeModal'
          @$router.push("/g/#{groupKey}")
      .catch (error) => true

    privacyStringFor: (privacy) ->
      @$t groupPrivacy(@group, privacy),
        parent: @group.parentName()

  computed:
    actionName: ->
      if @group.isNew() then 'created' else 'updated'

    titleLabel: ->
      if @group.isParent()
        "group_form.group_name"
      else
        "group_form.subgroup_name"

    privacyOptions: ->
      if @group.parentId && @group.parent().groupPrivacy == 'secret'
        ['closed', 'secret']
      else
        ['open', 'closed', 'secret']

    privacyStatement: ->
      @$t groupPrivacyStatement(@group),
        parent: @group.parentName()

    groupNamePlaceholder: ->
      if @group.parentId
        'group_form.subgroup_name_placeholder'
      else
        'group_form.organization_name_placeholder'

    groupNameLabel: ->
      if @group.parentId
        'group_form.subgroup_name'
      else
        'group_form.group_name'
