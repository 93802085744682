import { render, staticRenderFns } from "./stance_created.vue?vue&type=template&id=3b39bbd8&lang=pug&"
import script from "./stance_created.vue?vue&type=script&lang=coffee&"
export * from "./stance_created.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import ActionDock from '@/components/common/action_dock.vue'
import AttachmentList from '@/components/thread/attachment_list.vue'
import FormattedText from '@/components/common/formatted_text.vue'
import LinkPreviews from '@/components/thread/link_previews.vue'
import PollCommonStanceChoice from '@/components/poll/common/stance_choice.vue'
import PollCommonStanceChoices from '@/components/poll/common/stance_choices.vue'
import Space from '@/components/common/space.vue'
import StrandItemHeadline from '@/components/strand/item/headline.vue'
import TimeAgo from '@/components/common/time_ago.vue'
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {ActionDock,AttachmentList,FormattedText,LinkPreviews,PollCommonStanceChoice,PollCommonStanceChoices,Space,StrandItemHeadline,TimeAgo,VLayout})
