
import Records        from '@/shared/services/records'
import EventBus       from '@/shared/services/event_bus'
import AbilityService from '@/shared/services/ability_service'
import AppConfig      from '@/shared/services/app_config'

export default
  props:
    tag:
      type: Object
      required: true
    close: Function

  data: ->
    loading: false
    destroying: false

  methods:
    deleteTag: ->
      EventBus.$emit 'openModal',
        component: 'ConfirmModal'
        props:
          confirm:
            submit: @tag.destroy
            text:
              title:    'loomio_tags.destroy_tag'
              helptext: 'loomio_tags.destroy_helptext'
              submit:   'common.action.delete'
              flash:    'loomio_tags.tag_destroyed'

    submit: ->
      @loading = true
      @tag.save().then =>
        @close()
      .finally =>
        @loading = false

