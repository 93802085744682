
import OutcomeService from '@/shared/services/outcome_service'
import parseISO from 'date-fns/parseISO'
import { pickBy } from 'lodash'
export default
  props:
    outcome: Object
  methods:
    parseISO: parseISO

  data: ->
    actions: OutcomeService.actions(@outcome, @)

  computed:
    menuActions: ->
      pickBy OutcomeService.actions(@outcome, @), (v, k) -> v.menu
    dockActions: ->
      pickBy OutcomeService.actions(@outcome, @), (v, k) -> v.dock > 0

