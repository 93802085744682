
import {sum, map, sortBy, find, compact, uniq, slice, parseInt} from 'lodash'

export default
  props:
    poll: Object
    size: Number

  created: ->
    @watchRecords
      collections: ['pollOptions']
      query: (store) =>
        max = 10
        @results = slice @poll.results, 0, max
        @voterIds = slice @poll.decidedVoterIds(), 0, max

  computed:
    cellHeight: -> @size / @results.length
    cellWidth: -> @size / @voterIds.length
    cellSize: -> if (@cellHeight > @cellWidth) then @cellWidth else @cellHeight

  methods:
    classForScore: (score) ->
      switch score
        when 2 then 'poll-meeting-chart__cell--yes'
        when 1 then 'poll-meeting-chart__cell--maybe'
        when 0 then 'poll-meeting-chart__cell--no'
        else
          'poll-meeting-chart__cell--empty'

