

import { emojisByCategory, srcForEmoji } from '@/shared/helpers/emojis'
import { each, keys, pick } from 'lodash'

export default
  props:
    isPoll: Boolean
    insert:
      type: Function
      required: true

  data: ->
    search: ''
    showMore: false

  methods:
    srcForEmoji: srcForEmoji

  computed:
    emojis: ->
      if @showMore
        emojisByCategory
      else
        pick(emojisByCategory, ['common', 'hands', 'expressions'])

