
import { approximate, exact } from '@/shared/helpers/format_time'
import { parseISO } from 'date-fns'
import {isString} from 'lodash'

export default
  props:
    date: [Date, String]

  data: ->
    parsedDate: null

  created: ->
    if isString(@date)
      @parsedDate = parseISO(@date)
    else
      @parsedDate = @date

