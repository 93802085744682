
import AppConfig from '@/shared/services/app_config'

export default
  props:
    stance: Object

  computed:
    poll: -> @stance.poll()
    variableScore: -> @poll.hasVariableScore()
    pollType: -> @poll.pollType
    datesAsOptions: -> @poll.datesAsOptions()
    choices: -> @stance.sortedChoices()

  methods:
    emitClick: -> @$emit('click')

    colorFor: (score) ->
      switch score
        when 2 then AppConfig.pollColors.proposal[0]
        when 1 then AppConfig.pollColors.proposal[1]
        when 0 then AppConfig.pollColors.proposal[2]

