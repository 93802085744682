
import EventBus    from '@/shared/services/event_bus'
import AuthService from '@/shared/services/auth_service'
import AppConfig from '@/shared/services/app_config'

export default
  props:
    user: Object
    identity: Object

  data: ->
    loading: false
    email: ''

  methods:
    submit: ->
      @loading = true
      @user.email = @email
      AuthService.sendLoginLink(@user).then (=>), =>
        @user.errors = {email: [@$t('auth_form.email_not_found')]}
      .finally =>
        @loading = false
    createAccount: ->
      @user.createAccount = true
      @user.authForm = 'signUp'
  computed:
    siteName: -> AppConfig.theme.site_name

