

doctypes = []
import("@/../../config/doctypes.yml").then (data) ->
  keys = Object.keys(data).filter (k) -> parseInt(k).toString() == k
  values = keys.map (k) -> data[k]
  doctypes = values

export default
  props:
    model: Object
    preview: Object
    remove:
      default: null
      type: Function

  data: ->
    editing: false

  computed:
    doctype: ->
      doctypes.find((dt) => (new RegExp(dt.regex)).test(@preview.url)) || {name: 'other'}

    icon: ->
      if @doctype
        'mdi-'+@doctype.icon
      else
        'mdi-link-variant'

    iconColor: -> @doctype.icon

    hostname: ->
      new URL(@preview.url).host
