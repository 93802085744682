
import EventBus from '@/shared/services/event_bus'
import AppConfig from '@/shared/services/app_config'
import WebhookService from '@/shared/services/webhook_service'
import Records from '@/shared/services/records'
import Flash  from '@/shared/services/flash'
import openModal from '@/shared/helpers/open_modal'

export default
  props:
    close: Function
    group: Object

  data: ->
    webhooks: []
    loading: true

  mounted: ->
    Records.webhooks.fetch(params: {group_id: @group.id}).then => @loading = false
    @watchRecords
      collections: ["webhooks"]
      query: (records) =>
        @webhooks = records.webhooks.find(groupId: @group.id)

  methods:
    addAction: (group) ->
      WebhookService.addAction(group)
    webhookActions: (webhook) ->
      WebhookService.webhookActions(webhook)

