
import Session from '@/shared/services/session'
import TemplateBadge from '@/components/poll/common/template_badge'
import LmoUrlService from '@/shared/services/lmo_url_service'

export default
  components: {TemplateBadge}
  props:
    poll: Object
    displayGroupName: Boolean
  methods:
    showGroupName: ->
      @displayGroupName && @poll.groupId
  computed:
    link: -> LmoUrlService.discussionPoll(@poll)
