
import { differenceInDays, format, parseISO } from 'date-fns'
import Session         from '@/shared/services/session'
import Records         from '@/shared/services/records'
export default
  props:
    group: Object

  data: ->
    isHidden: Session.user().hasExperienced('old-plan-banner')

  methods:
    hideBanner: ->
      Records.users.saveExperience('old-plan-banner')
      @isHidden = true

  computed:
    isAdmin: ->
      @group.adminsInclude(Session.user())

    isOldPlan: ->
      plans = "pp-basic-monthly pp-pro-monthly ap-active-monthly npap-active-monthly pp-basic-annual pp-pro-annual pp-community-annual ap-active-annual ap-community-annual npap-active-annual small-monthly small-yearly"
      plans.includes(@group.subscription.plan)
