
import Records        from '@/shared/services/records'
import { orderBy } from 'lodash'
export default
  props:
    discussion: Object
  data: ->
    historyData: []
    historyLoading: false
    historyError: false
  created: ->
    @historyLoading = true
    Records.fetch(path: "discussions/#{@discussion.id}/history").then (data) =>
      @historyLoading = false
      @historyData = orderBy(data, ['last_read_at'], ['desc']) || []
    , (err) =>
      @historyLoading = false
      @historyError = true
