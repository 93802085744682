
import LinkPreview from '@/components/thread/link_preview'

export default
  components: {LinkPreview}
  props:
    model: Object
    remove:
      default: null
      type: Function
