
import EventBus from '@/shared/services/event_bus'
import AppConfig from '@/shared/services/app_config'
import Records from '@/shared/services/records'
import Flash  from '@/shared/services/flash'

export default
  props:
    chatbot: Object

  data: ->
    kinds: AppConfig.webhookEventKinds
    testing: false

  methods:
    submit: ->
      @chatbot.save()
      .then =>
        Flash.success 'chatbot.saved'
        EventBus.$emit('closeModal')
      .catch (b) =>
        Flash.error 'common.something_went_wrong'
        console.log @chatbot.errors

    destroy: ->
      @chatbot.destroy().then =>
        Flash.success 'poll_common_delete_modal.success'
        EventBus.$emit('closeModal')
      .catch (b) =>
        Flash.error 'common.something_went_wrong'
        console.log @chatbot.errors

    testConnection: ->
      @testing = true
      Records.remote.post('chatbots/test',
        server: @chatbot.server
        access_token: @chatbot.accessToken
        channel: @chatbot.channel
        group_name: "group name was here"
      ).finally =>
        Flash.success('chatbot.check_for_test_message')
        @testing = false

