import { render, staticRenderFns } from "./input.vue?vue&type=template&id=489d2e1c&lang=pug&"
import script from "./input.vue?vue&type=script&lang=coffee&"
export * from "./input.vue?vue&type=script&lang=coffee&"
import style0 from "./input.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import EmojiPicker from '@/components/emoji/picker.vue'
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {EmojiPicker,VBtn,VIcon,VMenu})
