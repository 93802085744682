import { render, staticRenderFns } from "./new_discussion.vue?vue&type=template&id=977b4de4&lang=pug&"
import script from "./new_discussion.vue?vue&type=script&lang=coffee&"
export * from "./new_discussion.vue?vue&type=script&lang=coffee&"
import style0 from "./new_discussion.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import ActionDock from '@/components/common/action_dock.vue'
import AttachmentList from '@/components/thread/attachment_list.vue'
import DiscussionTemplateBadge from '@/components/discussion/template_badge.vue'
import DocumentList from '@/components/document/list.vue'
import FormattedText from '@/components/common/formatted_text.vue'
import LinkPreviews from '@/components/thread/link_previews.vue'
import MidDot from '@/components/common/mid_dot.vue'
import StrandTitle from '@/components/strand/title.vue'
import TagsDisplay from '@/components/tags/display.vue'
import TimeAgo from '@/components/common/time_ago.vue'
import UserAvatar from '@/components/user/avatar.vue'
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {ActionDock,AttachmentList,DiscussionTemplateBadge,DocumentList,FormattedText,LinkPreviews,MidDot,StrandTitle,TagsDisplay,TimeAgo,UserAvatar,VBreadcrumbs,VChip,VIcon,VLayout,VSpacer})
