
import Records        from '@/shared/services/records'
import Session        from '@/shared/services/session'
import EventBus       from '@/shared/services/event_bus'
import AbilityService from '@/shared/services/ability_service'
import AppConfig      from '@/shared/services/app_config'
import { ContainerMixin, HandleDirective } from 'vue-slicksort'

export default
  props:
    model:
      type: Object
      required: true
    close: Function

  directives:
    handle: HandleDirective

  data: ->
    allTags: @model.group().parentOrSelf().tags()

  mounted: ->
    @watchRecords
      key: 'tags'+@model.id
      collections: ['tags']
      query: =>
        @allTags = @model.group().parentOrSelf().tags()

  computed:
    canAdminTags: ->
      @model.group().parentOrSelf().adminsInclude(Session.user())

  methods:
    openNewTagModal: ->
      EventBus.$emit 'openModal',
        component: 'TagsModal',
        props:
          tag: Records.tags.build(groupId: @model.group().parentOrSelf().id)
          close: =>
            EventBus.$emit 'openModal',
              component: 'TagsSelect',
              props:
                model: @model

    openEditTagModal: (tag) ->
      EventBus.$emit 'openModal',
        component: 'TagsModal',
        props:
          tag: tag.clone()
          close: =>
            EventBus.$emit 'openModal',
              component: 'TagsSelect',
              props:
                model: @model

    submit: ->
      EventBus.$emit 'closeModal'

    sortEnded: ->
      setTimeout =>
        Records.remote.post 'tags/priority',
          group_id: @model.groupId || @model.id
          ids: @allTags.map (t) -> t.id

