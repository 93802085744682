
import { eventHeadline, eventTitle, eventPollType } from '@/shared/helpers/helptext'
import LmoUrlService  from '@/shared/services/lmo_url_service'
import Records from '@/shared/services/records'

export default
  props:
    event: Object
    eventable: Object
    collapsed: Boolean

  computed:
    datetime: -> @eventable.castAt || @eventable.createdAt
    headline: ->
      actor = @event.actor()
      if @event.kind == 'new_comment' && @collapsed && @event.descendantCount > 0
        @$t('reactions_display.name_and_count_more', {name: actor.nameWithTitle(@eventable.group()), count: @event.descendantCount})
      else
        @$t eventHeadline(@event, true ), # useNesting
          author:   actor.nameWithTitle(@eventable.group())
          username: actor.username
          key:      @event.model().key
          title:    eventTitle(@event)
          polltype: @$t(eventPollType(@event)).toLowerCase()

    link: ->
      LmoUrlService.event @event

