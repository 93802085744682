
import AbilityService from '@/shared/services/ability_service'
import { map, compact, pick } from 'lodash'

export default
  props:
    poll: Object

  computed:
    groups: ->
      map compact([(@poll.groupId && @poll.group()), (@poll.discussionId && @poll.discussion())]), (model) =>
        if model.isA('discussion')
          text: model.name || model.title
          disabled: false
          to: @urlFor(model)+'/'+@poll.createdEvent().sequenceId
        else
          text: model.name || model.title
          disabled: false
          to: @urlFor(model)
