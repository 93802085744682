import { render, staticRenderFns } from "./preview.vue?vue&type=template&id=3a9a8c91&lang=pug&"
import script from "./preview.vue?vue&type=script&lang=coffee&"
export * from "./preview.vue?vue&type=script&lang=coffee&"
import style0 from "./preview.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import ActionDock from '@/components/common/action_dock.vue'
import ActionMenu from '@/components/common/action_menu.vue'
import ActiveTimeAgo from '@/components/common/active_time_ago.vue'
import DiscussionTemplateBadge from '@/components/discussion/template_badge.vue'
import MidDot from '@/components/common/mid_dot.vue'
import Space from '@/components/common/space.vue'
import TagsDisplay from '@/components/tags/display.vue'
import UserAvatar from '@/components/user/avatar.vue'
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {ActionDock,ActionMenu,ActiveTimeAgo,DiscussionTemplateBadge,MidDot,Space,TagsDisplay,UserAvatar,VChip,VIcon,VListItem,VListItemAction,VListItemAvatar,VListItemContent,VListItemSubtitle,VListItemTitle})
