
import Records       from '@/shared/services/records'
import Session from '@/shared/services/session'
import Flash from '@/shared/services/flash'
import AuthModalMixin from '@/mixins/auth_modal'
import openModal      from '@/shared/helpers/open_modal'
import AuthService from '@/shared/services/auth_service'
import EventBus from '@/shared/services/event_bus'

export default
  mixins: [AuthModalMixin]
  props:
    user: Object
  data: ->
    attempts: 0
    loading: false
  methods:
    submitAndSetPassword: ->
      @loading = true
      AuthService.signIn(@user).then =>
        EventBus.$emit 'openModal',
          component: 'ChangePasswordForm'
          props:
            user: Session.user()
      .finally =>
        @attempts += 1
        @loading = false
    submit: ->
      @loading = true
      AuthService.signIn(@user).finally =>
        @attempts += 1
        @loading = false
