
import { differenceInDays, format, parseISO } from 'date-fns'
import Session         from '@/shared/services/session'
import AuthModalMixin      from '@/mixins/auth_modal'
import Flash              from '@/shared/services/flash'
import Records            from '@/shared/services/records'
export default
  mixins: [ AuthModalMixin ]
  props:
    group: Object

  data: ->
    loading: false

  methods:
    signIn: -> @openAuthModal()
    cloneDemo: -> 
      @loading = true
      Flash.wait('templates.generating_demo')
      Records.post
        path: 'demos/clone'
        params:
          handle: @group.handle
      .then (data) =>
        Flash.success('templates.demo_created')
        @$router.push @urlFor(Records.groups.find(data.groups[0].id))
      .finally =>
        @loading = false

  computed:
    isLoggedIn: -> Session.isSignedIn()
    isPublicDemo: -> !!@group.handle
    isDemo: ->
      @group.subscription.plan == 'demo'
    daysRemaining: ->
      differenceInDays(parseISO(@group.subscription.expires_at), new Date) + 1
    createdDate: ->
      format(new Date(@group.createdAt), 'do LLLL yyyy')
