import { render, staticRenderFns } from "./page.vue?vue&type=template&id=899b9e60&lang=pug&"
import script from "./page.vue?vue&type=script&lang=coffee&"
export * from "./page.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import DiscussionForkActions from '@/components/discussion/fork_actions.vue'
import DiscussionTemplateBanner from '@/components/discussion/template_banner.vue'
import StrandCard from '@/components/strand/card.vue'
import StrandTocNav from '@/components/strand/toc_nav.vue'
import ThreadCurrentPollBanner from '@/components/thread/current_poll_banner.vue'
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {DiscussionForkActions,DiscussionTemplateBanner,StrandCard,StrandTocNav,ThreadCurrentPollBanner,VContainer,VMain})
