
import Records  from '@/shared/services/records'
import EventBus from '@/shared/services/event_bus'
import Flash  from '@/shared/services/flash'

export default
  props:
    user: Object
  data: ->
    contactRequest: Records.contactRequests.build(recipientId: @user.id)
  methods:
    submit: ->
      @contactRequest.save()
      .then =>
        Flash.success "contact_request_form.email_sent", {name: @user.name}
        @close()

