import { render, staticRenderFns } from "./card.vue?vue&type=template&id=49e246ea&lang=pug&"
import script from "./card.vue?vue&type=script&lang=coffee&"
export * from "./card.vue?vue&type=script&lang=coffee&"
import style0 from "./card.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import ActionDock from '@/components/common/action_dock.vue'
import PollCommonActionPanel from '@/components/poll/common/action_panel.vue'
import PollCommonCardHeader from '@/components/poll/common/card_header.vue'
import PollCommonChartPanel from '@/components/poll/common/chart/panel.vue'
import PollCommonDetailsPanel from '@/components/poll/common/details_panel.vue'
import PollCommonOutcomePanel from '@/components/poll/common/outcome_panel.vue'
import PollCommonSetOutcomePanel from '@/components/poll/common/set_outcome_panel.vue'
import PollCommonTypeIcon from '@/components/poll/common/type_icon.vue'
import PollCommonVotesPanel from '@/components/poll/common/votes_panel.vue'
import TagsDisplay from '@/components/tags/display.vue'
import Translation from '@/components/common/translation.vue'
import { VCardText } from 'vuetify/lib/components/VCard';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {ActionDock,PollCommonActionPanel,PollCommonCardHeader,PollCommonChartPanel,PollCommonDetailsPanel,PollCommonOutcomePanel,PollCommonSetOutcomePanel,PollCommonTypeIcon,PollCommonVotesPanel,TagsDisplay,Translation,VCardText,VSheet})
