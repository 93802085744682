import { render, staticRenderFns } from "./demo_banner.vue?vue&type=template&id=0ff7070c&lang=pug&"
import script from "./demo_banner.vue?vue&type=script&lang=coffee&"
export * from "./demo_banner.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import Space from '@/components/common/space.vue'
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {Space,VAlert,VBtn,VOverlay,VSpacer})
