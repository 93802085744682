
export default
  props:
    elevation:
      type: Number
      default: 0
    group: Object
    size:
      type: Number
      default: 28
