
import { approximate } from '@/shared/helpers/format_time'

export default
  props:
    date: Date

  methods:
    approximate: approximate

