
import Records from '@/shared/services/records'
import { max, values, orderBy, compact } from 'lodash'
import BarIcon from '@/components/poll/common/icon/bar.vue'
import PieIcon from '@/components/poll/common/icon/pie.vue'
import GridIcon from '@/components/poll/common/icon/grid.vue'
import Vue from 'vue'

export default
  components: {BarIcon, PieIcon, GridIcon}
  props:
    poll: Object

  data: ->
    users: {}
    slices: []

  watch:
    'poll.stanceCounts': -> @slices = @poll.pieSlices()
    
  methods:
    clampPercent: (num) -> Math.max(0, Math.min(num, 100))

  created: ->
    @watchRecords
      collections: ['users', 'stances']
      query: =>
        @poll.results.forEach (option) =>
          option.voter_ids.forEach (id) =>
            Vue.set @users, id, Records.users.find(id)

