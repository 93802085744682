
import AppConfig from '@/shared/services/app_config'
import EventBus from '@/shared/services/event_bus'
import Session from '@/shared/services/session'
import Records from '@/shared/services/records'
import marked from 'marked'
import md5 from 'md5'
import I18n from '@/i18n'

export default
  data: ->
    notice: false
    showNotice: false
    showDismiss: false
    reload: false

  mounted: ->
    setInterval =>
      Records.fetch
        path: 'boot/version'
        params:
          version: AppConfig.version
          release: AppConfig.release
          now: Date.now()
      .then(@eatData)
    ,
      1000 * 60 * 5
    EventBus.$on 'systemNotice', @eatData
    EventBus.$on 'signedIn', => @showNotice = false
    @eatData({version: AppConfig.version, notice: AppConfig.systemNotice})

  methods:
    eatData: (data) ->
      @reload = data.reload
      @notice = data.notice || (AppConfig.features.app.trials && @$route.path.startsWith('/d/') && !Session.isSignedIn() && I18n.t("powered_by.this_is_loomio_md"))
      @showNotice = @reload || (@notice && !Session.user().hasExperienced(md5(@notice)))
      @showDismiss = data.reload || data.notice

    accept: ->
      @showNotice = false
      @notice && Records.users.saveExperience(md5(@notice))
      if @reload
        setTimeout ->
          location.reload()
        , 100

