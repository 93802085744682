
import Session from '@/shared/services/session'
import AppConfig from '@/shared/services/app_config'
import GroupService from '@/shared/services/group_service'
import Flash from '@/shared/services/flash'
import Records from '@/shared/services/records'

export default
  props:
    group: Object

  data: ->
    key: null
    confirmReset: false
    loading: false

  created: ->
    Records.remote.get('profile/email_api_key').then (data) =>
      @key = data.email_api_key

  computed:
    address: ->
      "#{@group.fullName} <#{@group.handle}+u=#{Session.user().id}&k=#{@key}@#{AppConfig.theme['reply_hostname']}>"

  methods:
    resetKey: ->
      @loading = true
      Records.remote.post('profile/reset_email_api_key').then (data) =>
        @key = data.email_api_key
        Flash.success('email_to_group.new_address_generated')
      .finally =>
        @loading = false
        @confirmReset = false

    copyText: ->
      navigator.clipboard.writeText(@address);
      Flash.success("email_to_group.email_address_copied_to_clipboard")

    sendGroupAddress: ->
      Records.remote.post('profile/send_email_to_group_address', {group_id: @group.id}).then =>
        Flash.success('email_to_group.email_address_sent_to_you')

