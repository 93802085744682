
import RecipientsAutocomplete from '@/components/common/recipients_autocomplete'
import Session        from '@/shared/services/session'
import {map, filter, find} from 'lodash'

export default
  components:
    RecipientsAutocomplete: RecipientsAutocomplete

  props:
    model: Object

  data: ->
    initialRecipients: []

