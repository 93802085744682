
import AppConfig          from '@/shared/services/app_config'
import Records            from '@/shared/services/records'
import Session            from '@/shared/services/session'
import Flash              from '@/shared/services/flash'
import EventBus           from '@/shared/services/event_bus'
import AbilityService     from '@/shared/services/ability_service'

import {groupBy} from 'lodash'

export default
  data: ->
    records: {}
    tasksByRecordKey: {}
    loading: true

  mounted: ->
    Records.tasks.remote.fetch('/').then (data) =>
      ids = data['tasks'].map (t) -> t.id
      tasks = Records.tasks.find(ids).filter (t) -> t.record()?
      tasks.forEach (t) =>
        recordKey = t.recordType + t.recordId
        if !@records[recordKey]?
          @records[recordKey] = t.record()
      @tasksByRecordKey = groupBy tasks, (t) -> t.recordType + t.recordId
    .finally =>
      @loading = false

  methods:
    taskUrlFor: (record) ->
      if record.isA('discussion')
        @urlFor(record)+'/0'
      else
        @urlFor(record)

    toggleDone: (task) ->
      task.toggleDone().then ->
        if task.done
          Flash.success 'tasks.task_updated_done'
        else
          Flash.success 'tasks.task_updated_not_done'

