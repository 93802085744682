
import Records            from '@/shared/services/records'
import AbilityService     from '@/shared/services/ability_service'
import EventBus           from '@/shared/services/event_bus'
import RecordLoader       from '@/shared/services/record_loader'
import PageLoader         from '@/shared/services/page_loader'
import { map, debounce, orderBy, intersection, compact, omit, filter, concat, uniq} from 'lodash'
import Session from '@/shared/services/session'

export default
  created: ->
    @onQueryInput = debounce (val) =>
      @$router.replace(@mergeQuery(q: val))
    , 1000
    @init()
    EventBus.$on 'signedIn', @init

  beforeDestroy: ->
    EventBus.$off 'signedIn', @init

  data: ->
    group: null
    discussions: []
    searchResults: []
    loader: null
    searchLoader: null
    groupIds: []
    per: 25

  methods:
    routeQuery: (o) ->
      @$router.replace(@mergeQuery(o))

    openStartDiscussionModal: ->
      EventBus.$emit 'openModal',
        component: 'DiscussionForm'
        props:
          discussion: Records.discussions.build
            descriptionFormat: Session.defaultFormat()
            groupId: @group.id

    beforeDestroy: ->
      EventBus.$off 'joinedGroup'

    init: ->
      Records.groups.findOrFetch(@$route.params.key).then (group) =>
        @group = group

        EventBus.$emit 'currentComponent',
          page: 'groupPage'
          title: @group.name
          group: @group
          search:
            placeholder: @$t('navbar.search_threads', name: @group.parentOrSelf().name)

        EventBus.$on 'joinedGroup', (group) => @fetch()

        @refresh()

        @watchRecords
          key: @group.id
          collections: ['discussions', 'groups', 'memberships']
          query: => @query()

    refresh: ->
      @loader = new PageLoader
        path: 'discussions'
        order: 'lastActivityAt'
        params:
          group_id: @group.id
          exclude_types: 'group outcome poll'
          filter: @$route.query.t
          subgroups: @$route.query.subgroups || 'mine'
          tags: @$route.query.tag
          per: @per

      @searchLoader = new RecordLoader
        collection: 'searchResults'
        params:
          exclude_types: 'group stance outcome poll'
          subgroups: @$route.query.subgroups || 'all'
          group_id: @group.id

      @fetch()
      @query()

    query: ->
      return unless @group
      # console.log 'running query: page', @page, 'loader pageWindow[page]', @loader.pageWindow[@page]
      @publicGroupIds = @group.publicOrganisationIds()

      @groupIds = switch (@$route.query.subgroups || 'mine')
        when 'mine' then uniq(concat(intersection(@group.organisationIds(), Session.user().groupIds()), @publicGroupIds, @group.id)) # @group.id is present if @group is a subgroup of parentgroup that i'm a member of, and that parent group has parentMembersCanSeeDiscussions
        when 'all' then @group.organisationIds()
        else [@group.id]

      # console.log 'user group ids', Session.user().groupIds()
      # console.log 'org ids', @group.organisationIds()
      # console.log 'intersection ids', orderBy intersection(@group.organisationIds(), Session.user().groupIds())
      # console.log 'records found', Records.discussions.collection.chain().find(groupId: {'$in': @group.organisationIds()}).data().length

      if @$route.query.q
        chain = Records.searchResults.collection.chain()
        chain = chain.find(groupId: {$in: @group.parentOrSelf().organisationIds()})
        chain = chain.find(query: @$route.query.q)
        @searchResults = orderBy(chain.data(), 'rank', 'desc')
      else
        chain = Records.discussions.collection.chain()
        chain = chain.find(discardedAt: null)
        chain = chain.find(groupId: {$in: @groupIds})

        switch @$route.query.t
          when 'unread'
            chain = chain.where (discussion) -> discussion.isUnread()
          when 'closed'
            chain = chain.find(closedAt: {$ne: null})
          when 'templates'
            chain = chain.find(template: true)
          when 'all'
            true # noop
          else
            chain = chain.find(closedAt: null)

        if @$route.query.tag

          tag = Records.tags.find(groupId: @group.parentOrSelf().id, name: @$route.query.tag)[0]
          chain = chain.find({tagIds: {'$contains': tag.id}})

        if @loader.pageWindow[@page]
          if @page == 1
            chain = chain.find(lastActivityAt: {$gte: @loader.pageWindow[@page][0]})
          else
            chain = chain.find(lastActivityAt: {$jbetween: @loader.pageWindow[@page]})
          @discussions = chain.simplesort('lastActivityAt', true).data()
        else
          @discussions = []

    fetch: ->
      if @$route.query.q
        @searchLoader.fetchRecords(q: @$route.query.q, from: 0)
      else
        @loader.fetch(@page).then( => @query())

    filterName: (filter) ->
      switch filter
        when 'unread' then 'discussions_panel.unread'
        when 'all' then 'discussions_panel.all'
        when 'closed' then 'discussions_panel.closed'
        when 'templates' then 'templates.templates'
        when 'subscribed' then 'change_volume_form.simple.loud'
        else
          'discussions_panel.open'

  watch:
    '$route.params': 'init'
    '$route.query': 'refresh'
    'page' : ->
      @fetch()
      @query()

  computed:
    page:
      get: -> parseInt(@$route.query.page) || 1
      set: (val) ->
        @$router.replace({query: Object.assign({}, @$route.query, {page: val})}) 

    totalPages: ->
      Math.ceil(parseFloat(@loader.total) / parseFloat(@per))

    pinnedDiscussions: ->
      orderBy(@discussions.filter((discussion) -> discussion.pinnedAt), ['pinnedAt'], ['desc'])

    regularDiscussions: ->
      orderBy(@discussions.filter((discussion) -> !discussion.pinnedAt), ['lastActivityAt'], ['desc'])

    groupTags: ->
      @group && @group.parentOrSelf().tags().filter (tag) -> tag.taggingsCount > 0

    loading: ->
      @loader.loading || @searchLoader.loading

    noThreads: ->
      !@loading && @discussions.length == 0

    canViewPrivateContent: ->
      AbilityService.canViewPrivateContent(@group)

    canStartThread: ->
      AbilityService.canStartThread(@group)

    isLoggedIn: ->
      Session.isSignedIn()

    unreadCount: ->
      filter(@discussions, (discussion) -> discussion.isUnread()).length

    suggestClosedThreads: ->
      ['undefined', 'open', 'unread'].includes(String(@$route.query.t)) && @group && @group.closedDiscussionsCount

