
import { emojiReplaceText } from '@/shared/helpers/emojis.coffee'
import { merge } from 'lodash'
import Records from '@/shared/services/records'
import Session from '@/shared/services/session'
import Flash from '@/shared/services/flash'
import AbilityService from '@/shared/services/ability_service'

export default
  props:
    model:
      type: Object
      required: true
    column:
      type: String
      required: true

  mounted: ->
    @$el.addEventListener 'click', @onClick

  destroyed: ->
    @$el.removeEventListener 'click', @onClick

  methods:
    onClick: (e) ->
      if e.target.getAttribute('data-type') == 'taskItem' && (e.offsetX < e.target.offsetLeft) && !e.target.classList.contains('task-item-busy')
        if (@canEdit || e.target.querySelectorAll('span[data-mention-id="'+Session.user().username+'"]').length)
          e.target.classList.add('task-item-busy')
          uid = e.target.getAttribute('data-uid')
          checked = e.target.getAttribute('data-checked') == 'true'
          params = merge(@model.namedId(), {uid: uid, done: ((!checked && 'true') || 'false') })
          Records.remote.post('tasks/update_done', params).finally =>
            if !checked
              Flash.success 'tasks.task_updated_done'
            else
              Flash.success 'tasks.task_updated_not_done'
            e.target.classList.remove('task-item-busy')
        else
          alert(@$t('tasks.permission_denied'))

  computed:
    canEdit: -> AbilityService.canEdit(@model)
    isMd: -> @format == 'md'
    isHtml: -> @format == 'html'
    format: -> @model[@column+"Format"]
    text: -> emojiReplaceText(@model[@column])
    hasTranslation: -> @model.translation[@column] if @model.translation
    cookedText: ->
      return @model[@column] unless @model.mentionedUsernames
      cooked = @model[@column]
      @model.mentionedUsernames.forEach (username) ->
        cooked = cooked.replace(///@#{username}///g, "[@#{username}](/u/#{username})")
      cooked
