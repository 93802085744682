import { render, staticRenderFns } from "./requests_panel.vue?vue&type=template&id=0b87b0e7&lang=pug&"
import script from "./requests_panel.vue?vue&type=script&lang=coffee&"
export * from "./requests_panel.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import Loading from '@/components/common/loading.vue'
import MembershipRequest from '@/components/group/membership_request.vue'
import { VCard } from 'vuetify/lib/components/VCard';
import { VList } from 'vuetify/lib/components/VList';
installComponents(component, {Loading,MembershipRequest,VCard,VList})
