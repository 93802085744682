
export default
  props:
    action: Object
    name: String
    nameArgs: Object
    small: Boolean
  computed:
    text: -> @$t((@action.name || 'action_dock.'+@name), (@nameArgs || {}))
    cssClass: -> "action-dock__button--#{@name}"
