
import AppConfig                from '@/shared/services/app_config'
import EventBus                 from '@/shared/services/event_bus'
import RecordLoader             from '@/shared/services/record_loader'
import AbilityService           from '@/shared/services/ability_service'
import PollCommonForm from '@/components/poll/common/form'
import PollCommonChooseTemplate from '@/components/poll/common/choose_template'
import Session from '@/shared/services/session'
import AuthModalMixin from '@/mixins/auth_modal'
import Records from '@/shared/services/records'
import { compact, snakeCase, camelCase, max, map } from 'lodash'

export default
  components: {PollCommonForm, PollCommonChooseTemplate}
  mixins: [ AuthModalMixin ]

  props:
    discussion: Object

  data: ->
    canAddComment: false
    currentAction: 'add-comment'
    newComment: null
    poll: null

  created: ->
    @watchRecords
      key: @discussion.id
      collections: ['groups', 'memberships']
      query: (store) =>
        @canAddComment = AbilityService.canAddComment(@discussion)
    @resetComment()

  methods:
    resetComment: ->
      @newComment = Records.comments.build
        bodyFormat: Session.defaultFormat()
        discussionId: @discussion.id
        authorId: Session.user().id

    setPoll: (poll) -> @poll = poll
    resetPoll: ->
      @poll = null
      @currentAction = 'add-comment'

    signIn:     -> @openAuthModal()
    isLoggedIn: -> Session.isSignedIn()

  watch:
    'discussion.id': 'reset'

  computed:
    canStartPoll: ->
      AbilityService.canStartPoll(@discussion)

