
import { differenceInDays, format, parseISO } from 'date-fns'
import Session         from '@/shared/services/session'
import AuthModalMixin      from '@/mixins/auth_modal'
export default
  mixins: [ AuthModalMixin ]
  props:
    group: Object

  methods:
    signIn: -> @openAuthModal()

  computed:
    isLoggedIn: -> Session.isSignedIn()
    isWasGift: ->
      @group.subscription.plan == 'was-gift'
    isTrialing: ->
      @group.membersInclude(Session.user()) && @group.subscription.plan == 'trial'
    isExpired: ->
      @isTrialing && !@group.subscription.active
    daysRemaining: ->
      differenceInDays(parseISO(@group.subscription.expires_at), new Date) + 1
    createdDate: ->
      format(new Date(@group.createdAt), 'do LLLL yyyy')
