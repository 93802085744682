import { render, staticRenderFns } from "./removed.vue?vue&type=template&id=6617b7d4&lang=pug&"
import script from "./removed.vue?vue&type=script&lang=coffee&"
export * from "./removed.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import ActionDock from '@/components/common/action_dock.vue'
import MidDot from '@/components/common/mid_dot.vue'
import TimeAgo from '@/components/common/time_ago.vue'
installComponents(component, {ActionDock,MidDot,TimeAgo})
