
import Records from '@/shared/services/records'

export default
  props:
    comment: Object
    close: Function

  data: ->
    isDisabled: false

