import { render, staticRenderFns } from "./page.vue?vue&type=template&id=eda6a388&lang=pug&"
import script from "./page.vue?vue&type=script&lang=coffee&"
export * from "./page.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import LoadingContent from '@/components/common/loading_content.vue'
import ThreadPreview from '@/components/thread/preview.vue'
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {LoadingContent,ThreadPreview,VBtn,VCard,VContainer,VLayout,VList,VMain})
