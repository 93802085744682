import { render, staticRenderFns } from "./vote_form.vue?vue&type=template&id=c051b954&lang=pug&"
import script from "./vote_form.vue?vue&type=script&lang=coffee&"
export * from "./vote_form.vue?vue&type=script&lang=coffee&"
import style0 from "./vote_form.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import PollCommonStanceReason from '@/components/poll/common/stance_reason.vue'
import SortableItem from '@/components/common/sortable_item.vue'
import SortableList from '@/components/common/sortable_list.vue'
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSheet } from 'vuetify/lib/components/VSheet';
import ValidationErrors from '@/components/common/validation_errors.vue'
installComponents(component, {PollCommonStanceReason,SortableItem,SortableList,VBtn,VCardActions,VIcon,VListItem,VListItemAction,VListItemContent,VListItemIcon,VListItemSubtitle,VListItemTitle,VSheet,ValidationErrors})
