
import htmlDiff from "@/shared/helpers/html_diff"

export default
  props:
    before: String
    after: String
  methods:
    diff: -> htmlDiff(@before or '', @after)

