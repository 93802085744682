
import Session  from '@/shared/services/session'
import Records  from '@/shared/services/records'
import EventBus from '@/shared/services/event_bus'
import PollCommonDirective from '@/components/poll/common/directive'
import PollTemplateBanner from '@/components/poll/template_banner'
import PollService from '@/shared/services/poll_service'
import { pickBy } from 'lodash'

export default
  components: { PollCommonDirective, PollTemplateBanner}

  props:
    poll: Object
    isPage: Boolean

  created: ->
    EventBus.$on 'stanceSaved', => EventBus.$emit 'refreshStance'
    @watchRecords
      collections: ["stances", "outcomes"]
      query: (records) =>
        @actions = PollService.actions(@poll)
        @myStance = @poll.myStance() || Records.stances.build()
        @outcome = @poll.outcome()

  data: ->
    actions: {}
    buttonPressed: false
    myStance: null
    outcome: @poll.outcome()

  methods:
    titleVisible: (visible) ->
      EventBus.$emit('content-title-visible', visible) if @isPage

  computed:
    menuActions: ->
      pickBy @actions, (v) -> v.menu

    dockActions: ->
      pickBy @actions, (v) -> v.dock


