
import AppConfig from '@/shared/services/app_config'
import AbilityService from '@/shared/services/ability_service'
import Records from '@/shared/services/records'
import RecordLoader from '@/shared/services/record_loader'
import EventBus from '@/shared/services/event_bus'
import Session from '@/shared/services/session'
import { debounce, some, every, compact, omit, values, keys, intersection } from 'lodash'

export default
  data: ->
    group: null
    pollsLoader: null
    discussionsLoader: null
    tag: null

  created: ->
    @init()

    @watchRecords
      collections: ['polls', 'groups', 'discussions']
      query: => @findRecords()


  watch:
    '$route.params.tag': 'init'

  methods:
    init: ->
      @group = Records.groups.find(@$route.params.key)
      @tag = Records.tags.collection.find(name: @$route.params.tag, groupId: @group.id)[0]

      @pollsLoader = new RecordLoader
        collection: 'polls'
        params:
          exclude_types: 'group'
          tags: @$route.params.tag

      @discussionsLoader = new RecordLoader
        collection: 'discussions'
        params:
          exclude_types: 'group'
          filter: 'all'
          tags: @$route.params.tag

      @pollsLoader.fetchRecords()
      @discussionsLoader.fetchRecords()
      @findRecords()

    findRecords: ->
      @polls = Records.polls.collection.chain().
                find(tagIds: {$contains: @tag.id}).
                find(discardedAt: null).
                simplesort('createdAt', true).data()

      @discussions = Records.discussions.collection.chain().
                find(tagIds: {$contains: @tag.id}).
                find(discardedAt: null).
                simplesort('createdAt', true).data()

