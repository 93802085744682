
import EventBus from '@/shared/services/event_bus'

export default
  data: ->
    flash: {}
    isOpen: false
    message: ''
    seconds: 0
    timer: null
  created: ->
    EventBus.$on 'flashMessage', (flash) =>
      @flash = flash
      @isOpen = true
      @timer = window.setInterval =>
        @seconds += 3
      , 1000
  destroyed: ->
    clearInterval(timer)

