
import Records       from '@/shared/services/records'
import Session       from '@/shared/services/session'
import LmoUrlService from '@/shared/services/lmo_url_service'
import EventBus      from '@/shared/services/event_bus'
import AbilityService from '@/shared/services/ability_service'

export default
  props:
    groupId: Number
    discussion: Object

  data: ->
    templates: []

  mounted: ->
    Records.discussions.fetch
      params:
        group_id: @groupId
        filter: 'templates'
        exclude_types: 'user poll'
        per: 50

    @watchRecords
      key: "newDiscussionInGroup#{@groupId}"
      collections: ['discussions']
      query: =>
        @templates = Records.discussions.collection.chain().find(
          groupId: @groupId,
          template: true).data()

