
import Records  from '@/shared/services/records'
import EventBus from '@/shared/services/event_bus'
import Flash   from '@/shared/services/flash'
import { sum, map, head, filter, without, sortBy, isEqual } from 'lodash'

export default
  props:
    stance: Object

  data: ->
    pollOptions: []
    stanceChoices: []

  created: ->
    @watchRecords
      collections: ['pollOptions']
      query: (records) =>
        if @stance.poll().optionsDiffer(@pollOptions)
          @pollOptions = @stance.poll().pollOptionsForVoting()
          @stanceChoices = map @pollOptions, (option) =>
            option: option
            score: @stance.scoreFor(option)

  methods:
    submit: ->
      if sum(map(@stanceChoices, 'score')) > 0
        @stance.stanceChoicesAttributes = map @stanceChoices, (choice) =>
          poll_option_id: choice.option.id
          score: choice.score
      actionName = if !@stance.castAt then 'created' else 'updated'
      @stance.save()
      .then =>
        Flash.success "poll_#{@stance.poll().pollType}_vote_form.stance_#{actionName}"
        EventBus.$emit "closeModal"
      .catch => true

    rulesForChoice: (choice) ->
      [(v) => (v <= @maxForChoice(choice)) || @$t('poll_dot_vote_vote_form.too_many_dots')]

    percentageFor: (choice) ->
      max = dotsPerPerson
      return unless max > 0
      "#{100 * choice.score / max}% 100%"

    backgroundImageFor: (option) ->
      "url(/img/poll_backgrounds/#{option.color.replace('#','')}.png)"

    styleData: (choice) ->
      'border-color': choice.option.color
      'background-image': @backgroundImageFor(choice.option)
      'background-size': @percentageFor(choice)

    adjust: (choice, amount) ->
      choice.score += amount

    maxForChoice: (choice) ->
      @dotsPerPerson - sum(map(without(@stanceChoices, choice), 'score'))

  computed:
    poll: -> @stance.poll()
    
    dotsRemaining: ->
      @dotsPerPerson - sum(map(@stanceChoices, 'score'))

    dotsPerPerson: ->
      @stance.poll().dotsPerPerson

    alertColor: ->
      return 'success' if @dotsRemaining == 0 
      return 'primary' if @dotsRemaining > 0
      return 'error'   if @dotsRemaining < 0

