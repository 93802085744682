
import AppConfig     from '@/shared/services/app_config'
import Session       from '@/shared/services/session'
import Records       from '@/shared/services/records'
import EventBus      from '@/shared/services/event_bus'
import ThreadFilter from '@/shared/services/thread_filter'
# import StrandWall from '@/components/strand/wall'
import {each, keys, sum, values, sortBy} from 'lodash'

export default
  # components:
  #   StrandWall: StrandWall

  data: ->
    threadLimit: 50
    views: {}
    groups: []
    loading: false
    unreadCount: 0
    filters: [
      'only_threads_in_my_groups',
      'show_unread',
      'show_recent',
      'hide_muted',
      'hide_dismissed'
    ]

  created: ->
    @init()
    EventBus.$on 'signedIn', => @init()

  methods:
    startGroup: ->
      EventBus.$emit 'openModal',
        component: 'GroupNewForm',
        props: { group: Records.groups.build() }

    init: (options = {}) ->
      if Session.isSignedIn()
        @loading = true
        Records.discussions.fetchInbox(options).then => @loading = false

      EventBus.$emit 'currentComponent',
        titleKey: 'inbox_page.unread_threads'
        page: 'inboxPage'

      @watchRecords
        collections: ['discussions', 'groups']
        query: (store) =>
          @groups = sortBy Session.user().inboxGroups(), 'name'
          @views = {}
          each @groups, (group) =>
            @views[group.key] = ThreadFilter(filters: @filters, group: group)
          @unreadCount = sum values(@views), (v) -> v.length

