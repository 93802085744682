
import CommentService from '@/shared/services/comment_service'
import { pick } from 'lodash'
export default
  props:
    event: Object
    eventable: Object

  computed:
    menuActions: ->
      if @event.kind == 'new_comment'
        pick(CommentService.actions(@eventable, @), 'undiscard_comment', 'delete_comment')
      else
        {}
