
import EventBus from '@/shared/services/event_bus'
import AppConfig from '@/shared/services/app_config'
import Records from '@/shared/services/records'
import Flash  from '@/shared/services/flash'

export default
  props:
    close: Function
    webhook: Object

  data: ->
    tab: 'webhook'
    kinds: AppConfig.webhookEventKinds
    permissions: ['show_discussion', 'create_discussion', 'show_poll', 'create_poll', 'read_memberships', 'manage_memberships']

  methods:
    docsUrl: (key) ->
      AppConfig.baseUrl + "help/api?api_key=#{key}"

    submit: ->
      @webhook.save()
      .then =>
        Flash.success 'webhook.success'
        @close()
      .catch (b) =>
        console.log @webhook.errors
