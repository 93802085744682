

import AppConfig         from '@/shared/services/app_config'
import Session           from '@/shared/services/session'
import Records           from '@/shared/services/records'
import EventBus          from '@/shared/services/event_bus'
import AbilityService    from '@/shared/services/ability_service'
import GroupService    from '@/shared/services/group_service'
import LmoUrlService     from '@/shared/services/lmo_url_service'
import {compact, head, includes, filter, pickBy} from 'lodash'
import OldPlanBanner from '@/components/group/old_plan_banner'
import DemoBanner from '@/components/group/demo_banner'

export default
  components: { OldPlanBanner, DemoBanner }

  data: ->
    group: null
    activeTab: ''
    groupFetchError: null

  created: ->
    @init()
    EventBus.$on 'signedIn', @init

  beforeDestroy: ->
    EventBus.$off 'signedIn', @init

  watch:
    '$route.params.key': 'init'

  computed:
    dockActions: ->
      pickBy GroupService.actions(@group), (v) -> v.dock

    menuActions: ->
      pickBy GroupService.actions(@group), (v) -> v.menu

    canEditGroup: ->
      AbilityService.canEditGroup(@group)

    tabs: ->
      return unless @group
      query = ''
      query = '?subgroups='+@$route.query.subgroups if @$route.query.subgroups

      [
        {id: 0, name: 'threads',   route: @urlFor(@group, null)+query}
        {id: 1, name: 'polls',     route: @urlFor(@group, 'polls')+query},
        {id: 2, name: 'members',   route: @urlFor(@group, 'members')+query},
        {id: 4, name: 'files',     route: @urlFor(@group, 'files')+query}
        {id: 5, name: 'subgroups',  route: @urlFor(@group, 'subgroups')+query}
        # {id: 6, name: 'settings',  route: @urlFor(@group, 'settings')}
      ].filter (obj) => !(obj.name == "subgroups" && @group.parentId)

  methods:
    init: ->
      Records.groups.findOrFetch(@$route.params.key)
      .then (group) =>
        @group = group
        window.location.host = @group.newHost if @group.newHost
      .catch (error) =>
        EventBus.$emit 'pageError', error
        EventBus.$emit 'openAuthModal' if error.status == 403 && !Session.isSignedIn()

    openGroupSettingsModal: ->
      return null unless @canEditGroup
      EventBus.$emit 'openModal',
        component: 'GroupForm'
        props:
          group: @group

