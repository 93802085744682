
import ThreadService  from '@/shared/services/thread_service'
import { map, compact, pick, pickBy, omit } from 'lodash'
import EventBus from '@/shared/services/event_bus'
import openModal      from '@/shared/helpers/open_modal'
import StrandActionsPanel from '@/components/strand/actions_panel'

export default
  components:
    StrandActionsPanel: StrandActionsPanel
  props:
    event: Object
    eventable: Object
    collapsed: Boolean

  watch:
    'eventable.newestFirst': ->
      @actions = omit(ThreadService.actions(@eventable, @), ['dismiss_thread'])

  data: ->
    actions: omit(ThreadService.actions(@eventable, @), ['dismiss_thread'])

  mounted: ->
    @eventable.fetchUsersNotifiedCount()

  computed:
    author: ->
      @discussion.author()

    authorName: ->
      @discussion.authorName()

    discussion: -> @eventable

    group: ->
      @discussion.group()

    dockActions: ->
      pickBy @actions, (v) -> v.dock

    menuActions: ->
      pickBy @actions, (v) -> v.menu

    status: ->
      return 'pinned' if @discussion.pinned

    groups: ->
      @discussion.group().parentsAndSelf().map (group) =>
        text: group.name
        disabled: false
        to: @urlFor(group)

  methods:
    viewed: (viewed) ->
      @discussion.markAsSeen() if viewed

    openSeenByModal: ->
      openModal
        component: 'SeenByModal'
        props:
          discussion: @discussion

