
import AppConfig from '@/shared/services/app_config'
import { capitalize } from 'lodash'

export default
  props:
    user: Object

  methods:
    capitalize: capitalize

    select: (provider) ->
      # EventBus.emit $scope, 'processing'
      window.location = "#{provider.href}?back_to=#{window.location.href}"

    iconClass: (provider) ->
      "mdi-" + if (provider == 'saml') then 'key-variant' else provider

    providerColor: (provider) ->
      switch provider
        when 'facebook' then '#3b5998'
        when 'google' then '#dd4b39'
        when 'slack' then '#e9a820'
        when 'saml' then @$vuetify.theme.themes.light.primary

  computed:
    emailLogin: -> AppConfig.features.app.email_login
    providers: -> AppConfig.identityProviders.filter (provider) -> provider.name != 'slack'
