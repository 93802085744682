
import Flash   from '@/shared/services/flash'

export default
  props:
    membership: Object
  data: ->
    isDisabled: false
  methods:
    submit: ->
      @membership.save()
      .then =>
        Flash.success "membership_form.updated"
        @closeModal()

