
import Records  from '@/shared/services/records'
import EventBus from '@/shared/services/event_bus'

import { exact } from '@/shared/helpers/format_time'

export default
  props:
    model: Object
    close: Function

  created: ->
    @getVersion(@model.versionsCount - 1)

  data: ->
    index: 1
    version: null

  methods:
    getVersion: (index) ->
      @index = index
      @version = null
      Records.versions.fetchVersion(@model, index).then (data) =>
        @version = Records.versions.find(data.versions[0].id)

    getNext: ->
      if !@isNewest
        @getVersion(@index + 1)

    getPrevious: ->
      if !@isOldest
        @getVersion(@index - 1)

  computed:
    versionDate: ->
      exact @version.createdAt

    isOldest: ->
      @index == 0

    isNewest: ->
      @index == @model.versionsCount - 1

