
import Records from '@/shared/services/records'
import EventBus from '@/shared/services/event_bus'
import { pick } from 'lodash'

export default
  props:
    pollOption: Object
    poll: Object

  data: ->
    clone: pick(@pollOption, 'name', 'icon', 'meaning', 'prompt')
    icons: [
      {text: 'Thumbs up', value: 'agree'},
      {text: 'Thumbs down', value: 'disagree'},
      {text: 'Thumbs sideways', value: 'abstain'},
      {text: 'Hand up', value: 'block'}
    ],

  computed:
    hasOptionIcon: -> @poll.config().has_option_icon
    hasOptionPrompt: -> @poll.config().per_option_reason_prompt
    hasOptionMeaning: -> @poll.config().options_have_meaning

  methods:
    submit: ->
      Object.assign(@pollOption, @clone)
      EventBus.$emit('closeModal')

