
import {pickBy} from 'lodash'

export default
  props:
    model: Object
    actions:
      type: Object
      default: -> {}
    menuActions:
      type: Object
      default: -> {}
    small: Boolean
    left: Boolean
  computed:
    leftActions: ->
      pickBy @actions, (v) -> v.dockLeft

    rightActions: ->
      pickBy @actions, (v) -> !v.dockLeft
