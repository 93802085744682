
import AppConfig      from '@/shared/services/app_config'
import AbilityService from '@/shared/services/ability_service'
import Records  from '@/shared/services/records'
import Flash   from '@/shared/services/flash'
import EventBus   from '@/shared/services/event_bus'
import { groupPrivacy, groupPrivacyStatement } from '@/shared/helpers/helptext'
import { groupPrivacyConfirm } from '@/shared/helpers/helptext'
import { isEmpty, some, debounce } from 'lodash'

export default
  props:
    group: Object

  data: ->
    isDisabled: false
    rules: {
      required: (value) ->
        !!value || 'Required.'
    }
    uploading: false
    progress: 0
    realGroup: Records.groups.find(@group.id)

  mounted: ->
    @featureNames = AppConfig.features.group
    @suggestHandle()

  created: ->
    @suggestHandle = debounce ->
      # if group is new, suggest handle whenever name changes
      # if group is old, suggest handle only if handle is empty
      if @group.isNew() or isEmpty(@group.handle)
        parentHandle = if @group.parentId
          @group.parent().handle
        else
          null
        Records.groups.getHandle(name: @group.name, parentHandle: parentHandle).then (data) =>
          @group.handle = data.handle
    , 500

  methods:
    submit: ->
      allowPublic = @group.allowPublicThreads
      @group.discussionPrivacyOptions = switch @group.groupPrivacy
        when 'open'   then 'public_only'
        when 'closed' then (if allowPublic then 'public_or_private' else 'private_only')
        when 'secret' then 'private_only'

      @group.parentMembersCanSeeDiscussions = switch @group.groupPrivacy
        when 'open'   then true
        when 'closed' then @group.parentMembersCanSeeDiscussions
        when 'secret' then false

      @group.save().then (data) =>
        @isExpanded = false
        groupKey = data.groups[0].key
        Flash.success("group_form.messages.group_#{@actionName}")
        EventBus.$emit 'closeModal'
        @$router.push("/g/#{groupKey}")
      .catch (error) => true


    expandForm: ->
      @isExpanded = true

    privacyStringFor: (privacy) ->
      @$t groupPrivacy(@group, privacy),
        parent: @group.parentName()

    selectCoverPhoto: ->
      @$refs.coverPhotoInput.click()

    selectLogo: ->
      @$refs.logoInput.click()

    uploadCoverPhoto: ->
      @uploading = true
      Records.groups.remote.onUploadSuccess = (response) =>
        Records.importJSON response
        @uploading = false
      Records.groups.remote.upload("#{@group.id}/upload_photo/cover_photo", @$refs.coverPhotoInput.files[0], {}, (args) => @progress = args.loaded / args.total * 100)

    uploadLogo: ->
      @uploading = true
      Records.groups.remote.onUploadSuccess = (response) =>
        Records.importJSON response
        @uploading = false
      Records.groups.remote.upload("#{@group.id}/upload_photo/logo", @$refs.logoInput.files[0], {}, (args) => @progress = args.loaded / args.total * 100)

  computed:
    actionName: ->
      if @group.isNew() then 'created' else 'updated'

    titleLabel: ->
      if @group.isParent()
        "group_form.group_name"
      else
        "group_form.subgroup_name"

    privacyOptions: ->
      if @group.parentId && @group.parent().groupPrivacy == 'secret'
        ['closed', 'secret']
      else
        ['open', 'closed', 'secret']

    privacyStatement: ->
      @$t groupPrivacyStatement(@group),
        parent: @group.parentName()

    groupNamePlaceholder: ->
      if @group.parentId
        'group_form.group_name_placeholder'
      else
        'group_form.organization_name_placeholder'

    groupNameLabel: ->
      if @group.parentId
        'group_form.group_name'
      else
        'group_form.organization_name'
