
import OutcomeService from '@/shared/services/outcome_service'
import EventService from '@/shared/services/event_service'

import { pick } from 'lodash'

export default
  props:
    event: Object
    eventable: Object

  computed:
    dockActions: ->
      OutcomeService.actions(@eventable, @)
    menuActions: ->
      pick EventService.actions(@event, @), ['pin_event', 'unpin_event', 'notification_history']

