
import colors from 'vuetify/lib/util/colors'
import {map, compact, pick, keys} from 'lodash'

export default
  props:
    editor: Object

  data: ->
    colors: pick(colors, "red pink purple blue green yellow orange brown grey".split(" "))

  computed:
    activeColorKey: ->
      return null unless @editor.isActive('highlight')
      keys(@colors).find (name) => @editor.isActive('highlight', {color: name})

    buttonBgColor: ->
      (@colors[@activeColorKey] || {lighten1: null}).lighten2
    buttonFgColor: ->
      if @buttonBgColor
        '#000'
      else
        undefined
