import { render, staticRenderFns } from "./vote_form.vue?vue&type=template&id=404c195a&lang=pug&"
import script from "./vote_form.vue?vue&type=script&lang=coffee&"
export * from "./vote_form.vue?vue&type=script&lang=coffee&"
import style0 from "./vote_form.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import PollCommonStanceReason from '@/components/poll/common/stance_reason.vue'
import { VBanner } from 'vuetify/lib/components/VBanner';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VTextField } from 'vuetify/lib/components/VTextField';
import ValidationErrors from '@/components/common/validation_errors.vue'
installComponents(component, {PollCommonStanceReason,VBanner,VBtn,VCardActions,VForm,VListItem,VListItemAction,VListItemContent,VListItemSubtitle,VListItemTitle,VSlider,VTextField,ValidationErrors})
