
import prettyBytes from 'pretty-bytes'
export default
  props:
    attachment: Object

  data: ->
    backgroundSize: 'contain'
    backgroundPosition: 'center'

  mounted: ->
    # @setBackgroundSize()

  methods:
    prettifyBytes: (s) -> prettyBytes(s)
    setBackgroundSize: ->
      return unless @attachment.preview_url
      url = @attachment.preview_url
      img = new Image();
      that = @
      img.onload = ->
        if (Math.abs(@width - @height) < @width/3)
          that.backgroundSize = 'contain'
          that.backgroundPosition = 'center'
        else
          that.backgroundSize = 'cover'
          that.backgroundPosition = '0 5%'
      img.src = @attachment.preview_url

  watch:
    'attachment.preview_url': 'setBackgroundSize'
