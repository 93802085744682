
export default
  props:
    poll: Object
    stance: Object
    prompt: String
  computed:
    maxLength: ->
      if @poll.limitReasonLength
        500
      else
        undefined
