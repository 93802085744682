
import AppConfig from '@/shared/services/app_config'
import Session from '@/shared/services/session'
import { compact, without, kebabCase, snakeCase, some } from 'lodash'
import Flash from '@/shared/services/flash'
import Records from '@/shared/services/records'
import EventBus from '@/shared/services/event_bus'
import AbilityService from '@/shared/services/ability_service'
import { addDays, addMinutes, intervalToDuration, formatDuration } from 'date-fns'
import { addHours, isAfter } from 'date-fns'
import PollCommonWipField from '@/components/poll/common/wip_field'
import { HandleDirective } from 'vue-slicksort';
import { isSameYear, startOfHour, setHours }  from 'date-fns'

export default
  components: { PollCommonWipField }
  directives: { handle: HandleDirective }

  props:
    poll: Object
    shouldReset: Boolean
    redirectOnSave: Boolean

  mounted: ->
    Records.users.fetchGroups()
    @watchRecords
      collections: ['groups', 'memberships']
      query: =>
        @groupItems = [
          {text: @$t('discussion_form.none_invite_only_thread'), value: null}
        ].concat Session.user().groups().filter( (g) -> AbilityService.canStartPoll(g) ).map (g) ->
          {text: g.fullName, value: g.id}

  data: ->
    tab: 0
    newOption: null
    lastPollType: @poll.pollType
    pollOptions: @poll.pollOptionsAttributes || @poll.clonePollOptions()
    groupItems: []

    votingMethodsI18n:
      proposal: 
        title: 'poll_common_form.voting_methods.show_thumbs'
        hint: 'poll_common_form.voting_methods.show_thumbs_hint'
      poll: 
        title: 'poll_common_form.voting_methods.simple_poll'
        hint: 'poll_common_form.voting_methods.choose_hint'
      # meeting:
      #   title: 'poll_common_form.voting_methods.time_poll'
      #   hint: 'poll_common_form.voting_methods.time_poll_hint'
      dot_vote:
        title: 'decision_tools_card.dot_vote_title'
        hint: 'poll_common_form.voting_methods.allocate_hint'
      score: 
        title: 'poll_common_form.voting_methods.score'
        hint: 'poll_common_form.voting_methods.score_hint'
      ranked_choice:
        title: 'poll_common_form.voting_methods.ranked_choice'
        hint: 'poll_common_form.voting_methods.ranked_choice_hint'

    chartTypeItems: [
      {text: 'bar', value: 'bar'}
      {text: 'pie', value: 'pie'}
      {text: 'grid', value: 'grid'}
    ]
    currentHideResults: @poll.hideResults
    hideResultsItems: [
      { text: @$t('common.off'), value: 'off' }
      { text: @$t('poll_common_card.until_vote'), value: 'until_vote' }
      { text: @$t('poll_common_card.until_poll_type_closed', pollType: @poll.translatedPollType()), value: 'until_closed' }
    ]
    newDateOption: startOfHour(setHours(new Date(), 12))
    minDate: new Date()
    closingAtWas: null

  methods:
    optionHasVotes: (option) ->
      (@poll.results.find((o) -> o.id == option.id) || {voter_count: 0}).voter_count > 0

    setPollOptionPriority: ->
      i = 0
      @pollOptions.forEach (o) -> o.priority = 0
      @visiblePollOptions.forEach (o) -> o.priority = i++

    clearOptionsIfRequired: (newValue) ->
      @poll.applyPollTypeDefaults()
      if newValue == 'meeting' || @lastPollType == 'meeting'
        @pollOptions = []
      @lastPollType = newValue

    removeOption: (option) ->
      @newOption = null
      if option.id
        option.name = null
        option.meaning = null
        option['_destroy'] = 1
      else
        @pollOptions = without(@pollOptions, option)

    addDateOption: ->
      @newOption = @newDateOption.toJSON()
      @addOption()

    addOption: ->
      if some(@pollOptions, (o) => o.name.toLowerCase() == @newOption.toLowerCase())
        Flash.error('poll_poll_form.option_already_added')
      else
        knownOption = @knownOptions.find (o) =>
          @$t(o.name_i18n).toLowerCase() == @newOption.toLowerCase()

        if knownOption
          @pollOptions.push
            name: @newOption
            icon:  knownOption.icon
            meaning: @$t(knownOption.meaning_i18n)
            prompt: @$t(knownOption.prompt_i18n)
        else
          option = 
            name: @newOption
            meaning: ''
            prompt: ''
            icon: 'agree'
          @pollOptions.push option
          if @poll.pollType == 'proposal'
            Flash.success('poll_common_form.option_added_please_add_details')
            @editOption(option)

        @newOption = null

    editOption: (option) ->
      EventBus.$emit 'openModal',
        component: 'PollOptionForm'
        props:
          pollOption: option
          poll: @poll

    submit: ->
      actionName = if @poll.isNew() then 'created' else 'updated'
      @poll.setErrors({})
      @setPollOptionPriority()
      @poll.pollOptionsAttributes = @pollOptions
      @poll.save()
      .then (data) =>
        poll = Records.polls.find(data.polls[0].id)
        @$router.replace(@urlFor(poll)) if @redirectOnSave
        @$emit('saveSuccess', poll)
        Flash.success "poll_common_form.poll_type_started", {poll_type: poll.translatedPollTypeCaps()}
        if actionName == 'created'
          EventBus.$emit 'openModal',
            component: 'PollMembers',
            props:
              poll: poll
      .catch (error) =>
        Flash.warning 'poll_common_form.please_review_the_form'
        console.error error

  watch:
    'poll.template': (val) -> 
      if val
        @closingAtWas = @poll.closingAt
        @poll.closingAt = null
      else
        @poll.closingAt = @closingAtWas

  computed:
    votingMethodsItems: ->
      Object.keys(@votingMethodsI18n).map (key) =>
        {text: @$t(@votingMethodsI18n[key].title), value: key}

    knownOptions: ->
      (AppConfig.pollTypes[@poll.pollType].common_poll_options || [])

    example_if_template: ->
      (@poll.template && 'example_') || ''

    formattedDuration: -> 
      return '' unless @poll.meetingDuration
      minutes = parseInt(@poll.meetingDuration)
      duration = intervalToDuration({ start: new Date, end: addMinutes(new Date, minutes) })
      formatDuration(duration, { format: ['hours', 'minutes'] })

    visiblePollOptions: -> @pollOptions.filter (o) -> !o._destroy
    
    allowAnonymous: -> !@poll.config().prevent_anonymous
    stanceReasonRequiredItems: ->
      [
        {text: @$t('poll_common_form.stance_reason_required'), value: 'required'},
        {text: @$t('poll_common_form.stance_reason_optional'), value: 'optional'},
        {text: @$t('poll_common_form.stance_reason_disabled'), value: 'disabled'}
      ]

    titlePath: ->
      if @poll.template
        (@poll.isNew() && 'poll_common.new_poll_type') || 'poll_common.edit_poll_type'
      else
        (@poll.isNew() && 'action_dock.new_poll_type') || 'action_dock.edit_poll_type'

    titleArgs: -> 
      {pollType: @poll.translatedPollType().toLowerCase()}

    reminderEnabled: ->
      @poll.template || isAfter(@poll.closingAt, addHours(new Date(), 24))

    closingSoonItems: ->
      'nobody author undecided_voters voters'.split(' ').map (name) =>
        {text: @$t("poll_common_settings.notify_on_closing_soon.#{name}"), value: name}

    optionFormat: -> @poll.pollOptionNameFormat
    hasOptionIcon: -> @poll.config().has_option_icon
    i18nItems: -> 
      compact 'agree abstain disagree consent objection block yes no'.split(' ').map (name) =>
        return null if @poll.pollOptionNames.includes(name)
        text: @$t('poll_proposal_options.'+name)
        value: name

