

export default
  props:
    editor: Object

  methods:
    isActive: (alignment) ->
      @editor.isActive({ textAlign: alignment })

  computed:
    showOutline: ->
      @editor.isActive({ textAlign: 'right' }) ||
      @editor.isActive({ textAlign: 'center' })

    current: ->
      ['left', 'center', 'right'].find((v) => @isActive(v)) || "left"

    alignments: ->
      [
        { label: 'formatting.left_align', value: 'left' },
        { label: 'formatting.center_align', value: 'center' },
        { label: 'formatting.right_align', value: 'right' },
      ]

