
import AppConfig from '@/shared/services/app_config'

export default
  props:
    poll:
      type: Object
      required: true
    stanceChoice:
      type: Object
      required: true
    size:
      type: Number
      default: 24

  computed:
    color: ->
      @pollOption.color

    pollOption: ->
      @stanceChoice.pollOption

    pollType: ->
      @poll.pollType

    optionName: ->
      if AppConfig.pollTypes[@poll.pollType].poll_option_name_format == 'i18n'
        @$t('poll_' + @pollType + '_options.' + @stanceChoice.pollOption.name)
      else
        @stanceChoice.pollOption.name

  methods:
    emitClick: -> @$emit('click')

    colorFor: (score) ->
      switch score
        when 2 then AppConfig.pollColors.proposal[0]
        when 1 then AppConfig.pollColors.proposal[1]
        when 0 then AppConfig.pollColors.proposal[2]

