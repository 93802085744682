import { render, staticRenderFns } from "./tags_panel.vue?vue&type=template&id=57493a01&lang=pug&"
import script from "./tags_panel.vue?vue&type=script&lang=coffee&"
export * from "./tags_panel.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import Loading from '@/components/common/loading.vue'
import PollCommonPreview from '@/components/poll/common/preview.vue'
import TagsDisplay from '@/components/tags/display.vue'
import ThreadPreview from '@/components/thread/preview.vue'
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VList } from 'vuetify/lib/components/VList';
installComponents(component, {Loading,PollCommonPreview,TagsDisplay,ThreadPreview,VBtn,VCard,VCardTitle,VList})
