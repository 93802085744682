
import AppConfig from '@/shared/services/app_config'
import AbilityService from '@/shared/services/ability_service'
import Records from '@/shared/services/records'
import PageLoader from '@/shared/services/page_loader'
import EventBus       from '@/shared/services/event_bus'
import Session       from '@/shared/services/session'
import { debounce, some, every, compact, omit, values, keys, intersection, uniq } from 'lodash'

export default
  data: ->
    group: null
    polls: []
    loader: null
    pollTypes: AppConfig.pollTypes
    per: 25

  created: ->
    @onQueryInput = debounce (val) =>
      @$router.replace(@mergeQuery(q: val))
    , 500

    @group = Records.groups.find(@$route.params.key)

    @initLoader()

    @watchRecords
      collections: ['polls', 'groups', 'memberships']
      query: => @findRecords()

    @loader.fetch(@page).then =>
      EventBus.$emit 'currentComponent',
        page: 'groupPage'
        title: @group.name
        group: @group

  methods:
    initLoader: ->
      @loader = new PageLoader
        path: 'polls'
        order: 'createdAt'
        params:
          exclude_types: 'group'
          group_key: @$route.params.key
          status: @$route.query.status
          poll_type: @$route.query.poll_type
          query: @$route.query.q
          subgroups: @$route.query.subgroups
          per: @per

    openSelectPollTypeModal: ->
      EventBus.$emit 'openModal',
                     component: 'PollCommonStartForm'
                     props:
                       group: @group
                       isModal: true

    findRecords: ->
      groupIds = switch (@$route.query.subgroups || 'mine')
        when 'all' then @group.organisationIds()
        when 'none' then [@group.id]
        when 'mine' then uniq([@group.id].concat(intersection(@group.organisationIds(), Session.user().groupIds())))

      chain = Records.polls.collection.chain()
      chain = chain.find(groupId: {$in: groupIds})
      chain = chain.find(discardedAt: null)

      switch @$route.query.status
        when 'template'
          chain = chain.find({'template': true})
        when 'active'
          chain = chain.find({'closedAt': null})
        when 'closed'
          chain = chain.find({'closedAt': {$ne: null}})
        when 'vote'
          chain = chain.find({'closedAt': null}).where((p) -> p.iCanVote() && !p.iHaveVoted())

      if @$route.query.poll_type
        chain = chain.find({'pollType': @$route.query.poll_type})

      if @$route.query.q
        chain = chain.where (poll) =>
          some [poll.title, poll.details], (field) =>
            every @$route.query.q.split(' '), (frag) -> RegExp(frag, "i").test(field)

      if @loader.pageWindow[@page]
        if @page == 1
          chain = chain.find(createdAt: {$gte: @loader.pageWindow[@page][0]})
        else
          chain = chain.find(createdAt: {$jbetween: @loader.pageWindow[@page]})
        @polls = chain.simplesort('createdAt', true).data()
      else
        @polls = []

  watch:
    '$route.query.q': ->
      @initLoader().fetch(@page)
    '$route.query.status': ->
      @initLoader().fetch(@page)
    '$route.query.poll_type': ->
      @initLoader().fetch(@page)
    '$route.query.subgroups': ->
      @initLoader().fetch(@page)
    '$route.query.page': ->
      @loader.fetch(@page)

  computed:
    totalPages: ->
      Math.ceil(parseFloat(@loader.total) / parseFloat(@per))
    canStartPoll: -> AbilityService.canStartPoll(@group)
    page:
      get: -> parseInt(@$route.query.page) || 1
      set: (val) ->
        @$router.replace({query: Object.assign({}, @$route.query, {page: val})}) 
