
import openModal      from '@/shared/helpers/open_modal'
import AppConfig from '@/shared/services/app_config'

export default
  props:
    group: Object
  methods:
    openConfirmModalForJson: ->
      openModal
        component: 'ConfirmModal'
        props:
          confirm:
            submit: @group.export
            text:
              title:    'group_export_modal.title'
              helptext: 'group_export_modal.body'
              submit:   'group_export_modal.submit'
              flash:    'group_export_modal.flash'
  computed:
    baseUrl: ->
      AppConfig.baseUrl

